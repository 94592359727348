import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import FakeProgress from "@cospex/client/converter/components/FakeProgress";
import UploadInput from "@cospex/client/converter/components/UploadInput";
import UploadsUI from "@cospex/client/converter/components/UploadsUI";
import { OPERATION } from "@cospex/client/converter/constants";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import TextInput from "@cospex/client/forms/TextInput";
import { getSourceParams, setItemWithExpiry } from "@cospex/client/helpers";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Portal,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";

interface FormValues {
  email: string;
}

const UploadComponent = ({ sx }: { sx?: SxProps }) => {
  const { t, i18n } = useTranslation();
  const { uploads, removeAllUploads } = useUploads();
  const navigate = useNavigate();
  const upload = uploads[0];
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [fakeProgressActive, setFakeProgressActive] = useState(false);
  const { session } = useAuth();
  const { compressionFormat, convertFrom, convertTo } = getOperationFromQuery();
  const { utm_source, utm_medium, utm_campaign, gclid, fbclid, msclkid } =
    getSourceParams();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    ...(!session && {
      resolver: zodResolver(
        z.object({
          email: z.string().email(t("invalid-email")),
        })
      ),
    }),
    mode: "onChange",
    defaultValues: {
      email: (session?.email || localStorage.getItem("userEmail")) ?? "",
    },
  });

  const onSubmitNew = async (data: FormValues) => {
    const oneHour = 1000 * 60 * 60;
    setIsOnboarding(true);
    const email = data?.email;
    try {
      localStorage.setItem("userEmail", email);
      setItemWithExpiry("convertFrom", upload!.convertFrom!, oneHour);
      setItemWithExpiry("convertTo", upload!.convertTo!, oneHour);

      const {
        data: { id },
      } = await axios.post("/api/converter/onboarding", {
        email: email,
        language: i18n.language,
      });

      setItemWithExpiry("onboardingId", id, oneHour);
      upload.onComplete?.();
      setFakeProgressActive(true);

      axios.post(API_PATH_ANALYTICS, {
        email,
        utm_source,
        utm_medium,
        utm_campaign,
        gclid,
        fbclid,
        msclkid,
      });
    } catch (error) {
      console.log(error);
    }
    setIsOnboarding(false);
  };

  const onSubmitExisting = async () => {
    setIsOnboarding(true);
    await upload.onComplete?.();
    removeAllUploads();
    navigate("/dashboard/overview");
  };

  const hasUpload = Boolean(uploads[0]);
  const isUploadValid = Boolean(
    uploads[0]?.progress === 100 && uploads[0]?.convertTo
  );

  const getTitle = () => {
    if (hasUpload) {
      if (!isUploadValid) {
        if (compressionFormat !== null) {
          return t("uploader-title-compress-choose");
        } else {
          return t("uploader-title-convert");
        }
      } else {
        if (compressionFormat !== null) {
          return t("uploader-title-compress-email");
        } else {
          return t("uploader-title-email");
        }
      }
    } else {
      if (compressionFormat !== null) {
        return t("uploader-title-compress");
      } else {
        return t("uploader-title");
      }
    }
  };

  useEffect(() => {
    if (hasUpload && !session) {
      const emailInput = document.querySelector(
        "[name=email]"
      ) as HTMLInputElement;
      emailInput?.focus();
    }
  }, [hasUpload, session]);

  const buttonSx = {
    border: "2px solid #aa3030",
    py: 1.5,
    borderRadius: 2,
    "&:hover": {
      boxShadow: "0 4px 25px rgba(0,0,0,0.2)",
    },
  };
  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        p: {
          xs: 2,
          sm: 5,
          lg: 8,
        },
        zIndex: 1,
        borderColor: "primary.main",
        borderWidth: 2,
        borderStyle: "dashed",
        borderRadius: 4,
        ...sx,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        {getTitle()}
      </Typography>
      <div>
        <form onSubmit={handleSubmit(session ? onSubmitExisting : onSubmitNew)}>
          {!hasUpload && (
            <UploadInput
              limit={1}
              limitError={t("exceeded-homepage-upload-limit")}
              type={
                compressionFormat !== null
                  ? OPERATION.COMPRESSION
                  : OPERATION.CONVERSION
              }
              compressionStyle={
                compressionFormat !== null
                  ? { target: compressionFormat }
                  : undefined
              }
              conversionStyle={
                convertFrom && convertTo
                  ? { from: convertFrom, to: convertTo }
                  : undefined
              }
            />
          )}
          <UploadsUI />
          {hasUpload && !session && (
            <TextInput
              control={control}
              fullWidth
              name="email"
              label="Email"
              formState={formState}
              sx={{
                mb: 2,
              }}
            />
          )}
          {!hasUpload ? (
            <Button
              variant="contained"
              size="large"
              disableElevation
              type="submit"
              color="error"
              sx={buttonSx}
              fullWidth
              onClick={() => {
                (
                  document.querySelector("#fileInput") as HTMLInputElement
                ).click();
              }}
            >
              {t("choose-file-button")}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              disableElevation
              type="submit"
              color="error"
              sx={buttonSx}
              fullWidth
            >
              {compressionFormat !== null ? (
                <span>{t("uploader-button-compress")}</span>
              ) : (
                <span>{t("uploader-button")}</span>
              )}
            </Button>
          )}
        </form>
      </div>

      {fakeProgressActive ? (
        <Portal>
          <Stack
            sx={{
              p: 6,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              position: "fixed",
              zIndex: 999999,
              background: "rgba(0,0,0,0.8)",
              top: 0,
              left: 0,
              color: "white",
            }}
          >
            <FakeProgress
              onComplete={() => {
                navigate("/payment");
              }}
            />
          </Stack>
        </Portal>
      ) : null}
    </Card>
  );
};

export default UploadComponent;
