import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import Container from "@cospex/client/converter/components/Container";
import HomepageCounter from "@cospex/client/converter/components/HomepageCounter";
import PricingComponent from "@cospex/client/converter/components/PricingComponent";
import Uploader from "@cospex/client/converter/components/Upload";
import WhyChooseUs from "@cospex/client/converter/components/WhyChooseUs";
import {
  getExtensionColor,
  getOperationFromQuery,
} from "@cospex/client/converter/helpers";
import ArrowImage from "@cospex/client/converter/img/arrow.svg";
import HomeImage01 from "@cospex/client/converter/img/home_hero.svg";
import HomeImage11 from "@cospex/client/converter/img/home_image_1_1.svg";
import HomeImage12 from "@cospex/client/converter/img/home_image_1_2.svg";
import HomeImage13 from "@cospex/client/converter/img/home_image_1_3.svg";
import HomeImage21 from "@cospex/client/converter/img/home_image_2_1.svg";
import HomeImage22 from "@cospex/client/converter/img/home_image_2_2.svg";
import HomeImage23 from "@cospex/client/converter/img/home_image_2_3.svg";
import HomeStepsImage1 from "@cospex/client/converter/img/home_steps_image_1.svg";
import HomeStepsImage2 from "@cospex/client/converter/img/home_steps_image_2.svg";
import HomeStepsImage3 from "@cospex/client/converter/img/home_steps_image_3.svg";
import SecurityImage0 from "@cospex/client/converter/img/security_image_0.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { useParallax } from "react-scroll-parallax";

export default function Home() {
  return (
    <>
      <HeroSection />
      <HomepageCounter />
      <ReviewSection />
      <HowItWorksSection />
      <StepsSection />
      <WhyChooseUsSection />
      <FAQSection />
      <PricingSection />
      <SecuritySection />
    </>
  );
}

const TitleComponent = () => {
  const { t } = useTranslation();
  const { convertFrom, convertTo, compressionFormat } = getOperationFromQuery();
  const extensionColor = (extension: string) =>
    getExtensionColor(extension) || "primary.main";

  const fs = 32;
  const renderCompressionTitle = () => (
    <Typography sx={{ fontSize: fs, fontWeight: "bold" }}>
      {compressionFormat ? (
        <>
          {t("homepage-title-compress-a")}{" "}
          <Box
            component="span"
            sx={{ color: extensionColor(compressionFormat) }}
          >
            {compressionFormat.toUpperCase()}
          </Box>{" "}
          {t("homepage-title-compress-b")}
        </>
      ) : (
        <>
          {t("homepage-title-compress-default-a")}{" "}
          <Box component="span" sx={{ color: "primary.main" }}>
            {t("homepage-title-compress-default-b")}
          </Box>
        </>
      )}
    </Typography>
  );

  const renderConversionTitle = () => (
    <Typography sx={{ fontSize: fs, fontWeight: "bold" }}>
      {(() => {
        switch (true) {
          case !!(convertFrom && convertTo):
            return (
              <>
                {t("homepage-title-custom-a")}{" "}
                <Box
                  component="span"
                  sx={{ color: extensionColor(convertFrom) }}
                >
                  {convertFrom.toUpperCase()}
                </Box>{" "}
                {t("homepage-title-custom-b")}{" "}
                <Box component="span" sx={{ color: extensionColor(convertTo) }}>
                  {convertTo.toUpperCase()}
                </Box>
              </>
            );
          case !!convertFrom:
            return (
              <>
                {t("homepage-title-custom-a")}{" "}
                <Box
                  component="span"
                  sx={{ color: extensionColor(convertFrom) }}
                >
                  {convertFrom.toUpperCase()}
                </Box>{" "}
              </>
            );
          default:
            return (
              <>
                {t("homepage-title-a")} {t("homepage-title-b")}{" "}
                <Box component="span" sx={{ color: "primary.main" }}>
                  {t("homepage-title-c")}.
                </Box>
              </>
            );
        }
      })()}
    </Typography>
  );

  return compressionFormat !== null
    ? renderCompressionTitle()
    : renderConversionTitle();
};

function HeroSection() {
  const { t } = useTranslation();
  return (
    <>
      <Container
        sx={{
          pt: {
            xs: 4,
          },
          mb: {
            xs: 2,
            sm: 4,
            md: 8,
          },
        }}
      >
        <Grid container spacing={6} direction="row">
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              textAlign: "center",
              display: "flex",
              gap: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TitleComponent />
              <Typography variant="subtitle1" sx={{ mb: 4, fontSize: 14 }}>
                {t("homepage-subtitle")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  "> .MuiBox-root": {
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    textAlign: "left",
                  },
                  " .MuiTypography-root": {
                    fontSize: 14,
                  },
                  ".MuiSvgIcon-root": {
                    color: "primary.main",
                  },
                }}
              >
                <Box>
                  <CheckCircle />
                  <Typography>{t("homepage-chip-a")}</Typography>
                </Box>
                <Box>
                  <CheckCircle />
                  <Typography>{t("homepage-chip-b")}</Typography>
                </Box>
                <Box>
                  <CheckCircle />
                  <Typography>{t("homepage-chip-c")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              component="img"
              src={HomeImage01}
              alt="Hero Image"
              sx={{
                maxWidth: 400,
                height: "auto",
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Uploader
              sx={{
                flex: 1,
                display: {
                  md: "flex",
                },
                alignItems: "stretch",
                justifyContent: "center",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

function ReviewSection() {
  return (
    <Container
      sx={{
        overflow: "hidden",
        pb: {
          lg: 8,
          md: 4,
          xs: 2,
        },
      }}
    >
      <ReviewCarousel />
    </Container>
  );
}

function HowItWorksSection() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        pb: {
          lg: 14,
          md: 12,
          xs: 0,
        },
      }}
    >
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        {t("how-it-works")}
      </Typography>
      <Stack
        display="flex"
        sx={{
          flexDirection: {
            md: "row",
            xs: "column",
          },
          py: 8,
        }}
        gap={2}
      >
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 4,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
            transform: {
              md: "translateY(50px)",
              xs: "translateY(0px)",
            },
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage1}
          />
          <Typography variant="h5" color="primary.main">
            {t("homepage-steps-1-title")}
          </Typography>
          <Typography variant="body1">
            {t("homepage-steps-1-description")}
          </Typography>
        </Card>
        <Box
          sx={{
            pt: 6,
            display: {
              md: "block",
              xs: "none",
            },
          }}
        >
          <Box
            component="img"
            src={ArrowImage}
            sx={{
              width: {
                lg: 80,
                md: 40,
              },
            }}
          />
        </Box>
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage2}
          />
          <Typography variant="h5" color="primary.main">
            {t("homepage-steps-2-title")}
          </Typography>
          <Typography variant="body1">
            {t("homepage-steps-2-description")}
          </Typography>
        </Card>
        <Box
          sx={{
            pb: 6,
            display: {
              md: "flex",
              xs: "none",
            },
            alignItems: "flex-end",
          }}
        >
          <Box
            component="img"
            src={ArrowImage}
            sx={{
              width: {
                lg: 80,
                md: 40,
              },
              transform: "scaleY(-1)",
            }}
          />
        </Box>
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: 4,
            border: 1,
            borderColor: "primary.main",
            borderRadius: 2,
            transform: {
              md: "translateY(100px)",
              xs: "translateY(0px)",
            },
          }}
        >
          <Box
            component="img"
            style={{
              width: 70,
              height: 70,
            }}
            src={HomeStepsImage3}
          />
          <Typography variant="h5" color="primary.main">
            {t("homepage-steps-3-title")}
          </Typography>
          <Typography variant="body1">
            {t("homepage-steps-3-description")}
          </Typography>
        </Card>
      </Stack>
    </Container>
  );
}

function StepsSection() {
  const { t } = useTranslation();
  const steps = [
    t("homepage-step-a"),
    t("homepage-step-b"),
    t("homepage-step-c"),
  ];
  return (
    <Container
      backgroundColor="primary.main"
      sx={{
        overflow: "hidden",
        py: {
          md: 8,
          xs: 4,
        },
        "& p": {
          color: "white",
        },
        "& h5": {
          color: "white",
        },
        "& h3": {
          color: "white",
        },
      }}
    >
      <Grid
        container
        spacing={8}
        direction="row"
        alignItems="center"
        sx={{
          mb: {
            md: 8,
            xs: 4,
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              aspectRatio: "580/483",
              position: "relative",
              transform: "scale(.92)",
            }}
          >
            <Box
              ref={
                useParallax({
                  speed: -14,
                }).ref
              }
              component="img"
              src={HomeImage11}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -8,
                }).ref
              }
              component="img"
              src={HomeImage12}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -4,
                }).ref
              }
              component="img"
              src={HomeImage13}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={2}>
            <Typography variant="h5">
              {t("homepage-description-a-title")}
            </Typography>
            <Typography variant="body1">
              {t("homepage-description-a-body")}
            </Typography>
            <Box>
              <Box
                onClick={() => {
                  document.querySelector("#uploadInput")!.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                sx={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                {t("homepage-description-b-title")}
              </Box>
            </Box>
            <Typography variant="h5">
              {t("homepage-description-c-title")}
            </Typography>
            <Typography variant="body2">
              {t("homepage-description-c-body")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={8} direction="row" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h3" mb={4}>
              {t("homepage-steps-title")}
            </Typography>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 1,
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Typography>{step}</Typography>
                </Box>
                {index < steps.length - 1 && (
                  <Divider
                    sx={{
                      flex: 1,
                      height: 20,
                      backgroundColor: "white",
                      mr: 1,
                      width: "1px",
                      marginLeft: "20px",
                    }}
                    orientation="vertical"
                  />
                )}
              </React.Fragment>
            ))}
            <Button
              variant="outlined"
              disableElevation
              size="large"
              onClick={() => {
                document.querySelector("#uploadInput")!.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
              sx={{
                backgroundColor: "white",
                color: "primary.main",
                borderColor: "white",
                mt: 4,
                "&:hover": {
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
            >
              {t("homepage-steps-button")}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              aspectRatio: "580/483",
              position: "relative",
              transform: "scale(.92)",
            }}
          >
            <Box
              ref={
                useParallax({
                  speed: -14,
                }).ref
              }
              component="img"
              src={HomeImage21}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -8,
                }).ref
              }
              component="img"
              src={HomeImage22}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
            <Box
              ref={
                useParallax({
                  speed: -4,
                }).ref
              }
              component="img"
              src={HomeImage23}
              alt="Hero Image"
              sx={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

function WhyChooseUsSection() {
  return (
    <Container
      sx={{
        py: 8,
      }}
    >
      <WhyChooseUs />
    </Container>
  );
}

function FAQSection() {
  const { t } = useTranslation();
  return (
    <Container>
      <Box>
        <Stack alignItems="center">
          <Typography variant="h2" sx={{ padding: "20px" }}>
            {t("homepage-faq-title")}
          </Typography>
        </Stack>
        <Box
          sx={{
            "> .MuiAccordion-root": {
              boxShadow: "none",
              margin: "16px 0",
            },
            "> .MuiAccordion-root:before": {
              display: "none",
            },
            ".MuiTypography-body2": {
              fontSize: "1.2rem",
            },
            borderRadius: "8px",
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-a-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-a-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-b-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-b-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-c-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-c-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-d-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-d-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1e-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {t("homepage-faq-accordion-e-title")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t("homepage-faq-accordion-e-body")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Container>
  );
}

function PricingSection() {
  return (
    <Container sx={{ py: 8 }}>
      <PricingComponent
        action={() => {
          document.querySelector("#uploadInput")!.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      />
    </Container>
  );
}

function SecuritySection() {
  const { t } = useTranslation();
  return (
    <Container
      backgroundColor="#1A0F4E"
      sx={{
        py: 4,
        mt: {
          md: 8,
          xs: 0,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        gap={4}
      >
        <Typography variant="h6" color="white">
          {t("homepage-security-title")}
        </Typography>
        <Box
          ref={
            useParallax({
              speed: -4,
            }).ref
          }
          component="img"
          src={SecurityImage0}
          alt="Security Image 0"
          sx={{
            maxWidth: 150,
            mt: {
              md: -12,
              xs: 0,
            },
            width: {
              md: "auto",
              xs: 150,
            },
          }}
        />
      </Stack>
    </Container>
  );
}
